import { ChainId } from "@usedapp/core";

export const chainIdsToRPC = {
  [ChainId.Localhost]: {
    rpc: "http://127.0.0.1:8545/",
    name: "localhost",
  },
  [ChainId.Mainnet]: {
    rpc: "https://mainnet.infura.io/v3/a5afec4445a3489da6bdda32a7e7d81c",
    name: "Mainnet",
  },
  [ChainId.Hardhat]: {
    rpc: "http://127.0.0.1:8545/",
    name: "hardhat",
  },
  [ChainId.Mumbai]: {
    rpc: "https://polygon-mumbai.g.alchemy.com/v2/fVEyy8OofsHgkXw6WHjxI9UPaj-d4tYO",
    name: "Mumbai",
  },
  [ChainId.Polygon]: {
    rpc: `https://polygon-mainnet.g.alchemy.com/v2/${process.env.NEXT_PUBLIC_ALCHEMY_KEY}`,
    name: "Polygon",
  },
};

export const coaches = [
  "lauren",
  "Reign & Neen",
  "sam",
  "Bobby & Joe",
  "elise",
  "crews",
  "lauren",
];

export const defaultText = {
  key: "lauren",
  upper: "PROOF OF WORKOUT",
  title: "FROM ELITE COACHES",
  description:
    "Some NFTs can’t be bought. No matter how much ETH you have. Some have to be earned. Complete workouts with your coach in the Ladder app to earn your NFTs",
};

export const coachData = [
  {
    key: "lauren",
    upper: "Coach Lauren",
    title: "BODY & BELL",
    description:
      "Reinvigorate your fitness plan, develop new skills, and sweat alongside the best with this full body strength and fat loss program. Featuring kettlebell skills development, upper/lower body, core and booty strength workouts and yoga mobility.",
  },
  {
    key: "td",
    upper: "Coach Reign & Neen",
    title: "TREIGNING DAY",
    description:
      "Unleash your inner beast as African Viking Rein Nel and Pro-Skateboarder Neen Williams coach you through progressive workouts delivered fresh each week.",
  },
  {
    key: "sam",
    upper: "COACH SAM",
    title: "PROJECT ALPHA",
    description:
      "Get ready for some serious hustle with this speed, power, endurance and strength training program designed to improve your mindset and push you to new limits. Featuring next level circuit-style strength training and outdoor sprint workouts designed for at home or the gym.",
  },
  {
    key: "maximus",
    upper: "Coach Joe & Bobby",
    title: "MAXIMUS",
    description:
      "Transform your body and mind by mastering the fundamentals with the most respected Coaches in fitness. These minimal equipment, effective 30 min daily workouts deliver incredible strength, muscle-building, and fat-burning effects with upper body, lower body and HIIT strength training.",
  },
  {
    key: "elise",
    upper: "Coach Elise",
    title: "ELIVATE",
    description:
      "Perfect for those who are busy but make time to workout; it’s efficient, effective and challenging. This complete strength training plan includes high intensity interval training, muscle group focused push and pull strength circuits and cardio.",
  },
  {
    key: "andre",
    upper: "Coach Crews",
    title: "CREWS CONTROL",
    description:
      "Train like an athlete as you build lean muscle and decrease body fat with this functional movement and strength training program coached by Men’s Health Magazine’s Fittest Trainer. Workouts feature strength training performed at a high intensity level, skill work and functional exercises.",
  },
];
