import { ethers } from "ethers";

import LadderNFT from "../../artifacts/contracts/Ladder_NFT_V1.sol/Ladder_NFT_V1.json";
import { useWeb3React } from "@web3-react/core";
import { useMemo } from "react";
import { chainIdsToRPC } from "~/constants";

const getContract = () => {
  if ("undefined" === typeof window?.ethereum) return {};
  const provider = new ethers.providers.Web3Provider(window.ethereum);
  // const provider = new ethers.providers.JsonRpcProvider(
  //   chainIdsToRPC[process.env.NEXT_PUBLIC_CHAIN_ID]?.rpc
  // );
  const signer = provider.getSigner();

  const contract = new ethers.Contract(
    process.env.NEXT_PUBLIC_CONTRACT_ADDRESS,
    LadderNFT.abi,
    signer
  );

  return {
    contract,
    provider,
    signer,
  };
};

export const useLadderContract = () => {
  const { account } = useWeb3React();
  const { contract, provider, signer } = useMemo(
    () => getContract(account),
    [account]
  );

  return {
    contract,
    provider,
    signer,
  };
};
